<template>
	<el-dialog title="新闻详情" :visible.sync="showdetail" width="45%">
		<div style="width: 100%;">
			<div class="content" v-if="detail">
				<div style="color: #2C65AF;font-size:28px;text-align:center;">{{detail.name}}</div>
				<div style="width: 100%;margin: 10px 0;padding: 10px 0;background-color: #F3F3F3;display: flex;align-items: center;justify-content: space-around;">
					<div style="color: #666666;font-size:16px;">来源：{{detail.laiyuan}}</div>
					<div style="color: #666666;font-size:16px;">发布时间：{{detail.fbtime?detail.fbtime:detail.createtime}}</div>
				</div>
				<div class="myhtml" v-html="detail.content" style="font-size: 20px;line-height: 1.5;padding: 0 .6rem;">
					
				</div>
				<div style="display: flex;font-size: 0.18rem;" v-show="detail.fujian&&detail.fujian.length>0">
					<div>附件列表：</div>
					<div>
						<div v-for="item in detail.fujian" @click="openpage(item)" style="margin-bottom: 10px;color: #0000ff;cursor: pointer;">
							{{item.filename}}
						</div>
					</div>
					
				</div>
			</div>
		</div>
	
		<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 20px;">
			<el-button @click="showdetail=false" size="small">关闭</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return{
				showdetail:false,
				detail:null
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content{
		height:60vh;
		overflow-y: auto;
	}
	::v-deep .el-dialog__header {
		display: flex !important;
	}
	
	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>