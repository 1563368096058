<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">标题：</span>
					<el-input v-model="searchinput" placeholder="请输入标题" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<span style="width: 0.9rem;">所属分类：</span>
					<el-cascader v-model="searchtype" :props="props" @change="changejilian" clearable placeholder="请选择"
						style="width: 2.5rem;"></el-cascader>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="reset">重置</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="240" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button v-if="scope.row.type==3" @click="showliyou(scope.row)" type="warning"
								size="mini">驳回理由</el-button>
							<el-button v-if="scope.row.type==3" @click="showedit(scope.row)" type="success"
								size="mini">编辑</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>

		<newsdetail ref="mydetail"></newsdetail>
	</div>
</template>

<script>
	import newsdetail from '../component/detail.vue'
	export default {
		components: {
			newsdetail
		},
		data() {
			let that = this;
			return {
				detail_id: '',
				searchinput: '',
				searchtype: "",
				searchtype2: "",
				tableHeader: [{
						name: '标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '所属分类',
						prop: 'classify_name',
						width: ''
					},
					{
						name: '阅读量',
						prop: 'readcount',
						width: ''
					},
					{
						name: '排序',
						prop: 'weigh',
						width: '120'
					},
					{
						name: '状态',
						prop: 'type',
						width: '120',
						type: 'newssh_type'
					},
					{
						name: '发布时间',
						prop: 'fbtime',
						width: ''
					}
				],
				tableData: [],
				props: {
					lazy: true,
					lazyLoad(node, resolve) {
						that.$post({
							url: '/api/classify/index',
							params: {
								pid: node.level == 0 ? 0 : node.value,
								p: 1,
								size: 9999
							}
						}).then((res) => {
							res.list = res.list.map(item => ({
								value: item.id,
								label: item.name,
								leaf: item.ej.length > 0 ? false : true
							}))
							resolve(res.list);
						})
					}
				},

			}
		},
		mounted() {
			this.gettable()
		},
		activated() {
			this.gettable(); // 每次进入页面时刷新数据
		},
		methods: {
			showdetail(row) {
				if (row.tzurl) {
					window.open(row.tzurl, '_blank')
					return
				}
				this.$refs.mydetail.detail = row
				this.$refs.mydetail.showdetail = true
			},
			showedit(row) {
				this.$router.push({
					path: '/system/news_edit',
					query: {
						id: row.id
					}
				})
			},
			changejilian(e) {
				console.log(e)
				if (this.searchtype.length >= 2) { //有二级
					this.searchtype2 = e[1]

				} else if (this.searchtype.length == 1) { //没有二级
					this.searchtype2 = e[0]
				} else {
					this.searchtype2 = ''
				}
				this.gettable()
			},

			addnew() {
				this.$router.push('/system/news_add')
			},
			reset() {
				this.searchinput = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/news/qxindex',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						classify_id: this.searchtype2
					}
				}).then((res) => {

					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			showliyou(row) {
				this.$confirm(row.liyou||'无', '驳回理由', {
					confirmButtonText: '修改',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						path: '/system/news_edit',
						query: {
							id: row.id
						}
					})
				}).catch(() => {
					
				});
			},
			bohui(row) {
				this.$confirm('确认是否驳回该条新闻吗？')
					.then(_ => {
						this.$post({
							url: '/api/news/shqx',
							params: {
								id: row.id,
								type: 3
							}
						}).then((res) => {
							this.$message.success('驳回成功')
							this.gettable()
						})
					})
					.catch(_ => {});
			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.$router.push({
					path: '/system/news_edit',
					query: {
						id: row.id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>